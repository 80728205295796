import React from 'react';
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
//import me2 from '../assets/img/me2.png';
import pc from '../assets/img/pc2.png';
import { Link } from "react-scroll"; 
import { TypeAnimation } from 'react-type-animation';

const Home = () => {
  return (
    <div
    name="home"
    className="h-screen w-full bg-[#0a192f]"
  >
    <div className="max-w-screen-lg mx-auto flex flex-col items-center justify-center h-full px-4  md:flex-row">
      <div className="flex flex-col justify-center h-full">
        <h2 className="introtext text-4xl sm:text-3xl font-bold text-white">
          Hello! My name is Nate Jones and I'm a <TypeAnimation
          sequence={[
            // Same substring at the start will only be typed out once, initially
            'Backend',
            1200,// wait 1s before replacing "Mice" with "Hamsters"
            'Frontend',
            1200,
            'Python',
            1000,
            'React',
            1000
          ]}
          wrapper="span"
          speed={50}
          repeat={Infinity} 
        />
        Developer
        </h2>
        <p className="text-gray-500 py-4 max-w-28rem sm:text-center">
          I have 5 years of experience in digital design and web development.
          Currently, I love to work on web applications, backend applications using technologies like
          React, Python, Node,js, Firebase, and MongoDB.
        </p>
        <div>
          <Link
            to="about"
            smooth
            duration={500}
            className="group text-white w-fit px-6 py-3 my-2 flex items-center rounded-md bg-gradient-to-r from-cyan-500 to-blue-600 cursor-pointer"
          >
            About NJ
            <span className="group-hover:rotate-90 duration-300">
              <MdOutlineKeyboardArrowRight size={25} className="ml-3" />
            </span>
          </Link>
        </div>
      </div>
      <div>
        <img
          src={pc}  
          alt="my profile"
          className="rounded-2xlxl mx-auto w-3/3 md:w-full object-contain
          "
        />
      </div>
    </div>
  </div>
  );
};
export default Home;